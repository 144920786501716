import { useKeycloak } from "@react-keycloak/web";
import { Billing } from "components/Billing";
import { InvitationAcceptance } from "components/InvitationAcceptance";
import { Nlp } from "components/Nlp";
import { usePrevious } from "hooks/usePrevious";
import * as authActions from "modules/auth/actions";
import { apiSessionSelector } from "modules/auth/selectors";
import * as pollingActions from "modules/polling/actions";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { isNull } from "typeGuards/isNull";
import { ROUTES } from "../../constants";
import { Administrators } from "../Administrators";
import { Cluster } from "../Cluster";
import { Clusters } from "../Clusters";
import { Loader } from "../common/Loader";
import { RequireAuth } from "../common/RequireAuth";
import { Container } from "../Container";
import { Database } from "../Database";
import { Databases } from "../Databases";
import { Firewall } from "../Firewall";
import { FirewallRule } from "../FirewallRule";
import { Group } from "../Group";
import { Groups } from "../Groups";
import { Network } from "../Network";
import { Networking } from "../Networking";
import { Organization } from "../Organization";
import { Organizations } from "../Organizations";
import { Project } from "../Project";
import { Router } from "../Router";
import { Security } from "../Security";
import { Storage } from "../Storage";
import { VirtualMachine } from "../VirtualMachine";
import { VirtualMachines } from "../VirtualMachines";
import { S3Buckets } from "components/S3Buckets";
import { S3Bucket } from "components/S3Bucket";
import { BackupsWorkloads } from "components/BackupsWorkloads";
import { BackupsWorkload } from "components/BackupsWorkload";
import { BackupsSnapshot } from "components/BackupsSnapshot";

const POLL_ID_PREFIX = "ROUTER";

const POLL_IDS = {
  userDetails: "USER_DETAILS"
};

export const AppRouter: FC = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const apiSession = useSelector(apiSessionSelector);
  const previousApiSession = usePrevious(apiSession);

  useEffect(() => {
    if (!keycloak.authenticated) {
      void keycloak.login();
    } else {
      dispatch(authActions.apiRelogin.started());
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.userDetails}`,
          action: authActions.getUserDetails.started({})
        })
      );
    }
  }, [keycloak, keycloak.authenticated, dispatch]);

  useEffect(() => {
    if (isNull(previousApiSession) && apiSession) {
      dispatch(authActions.startRefreshApiSessionPolling());
    }
  }, [previousApiSession, apiSession, dispatch]);

  return keycloak.authenticated ? (
    apiSession ? (
      <BrowserRouter
        future={{
          v7_relativeSplatPath: true,
          v7_startTransition: true
        }}
      >
        <Container>
          <Routes>
            <Route
              path={ROUTES.ROOT}
              element={
                <RequireAuth>
                  <Organizations />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ORGANIZATIONS}
              element={
                <RequireAuth>
                  <Organizations />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ORGANIZATION_INVITATION_ACCEPTANCE}
              element={
                <RequireAuth>
                  <InvitationAcceptance />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ADMINISTRATORS}
              element={
                <RequireAuth>
                  <Administrators />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.GROUPS}
              element={
                <RequireAuth>
                  <Groups />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.GROUP}
              element={
                <RequireAuth>
                  <Group />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.GROUP_INVITATION_ACCEPTANCE}
              element={
                <RequireAuth>
                  <InvitationAcceptance />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ORGANIZATION}
              element={
                <RequireAuth>
                  <Organization />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.BILLING}
              element={
                <RequireAuth>
                  <Billing />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.PROJECT}
              element={
                <RequireAuth>
                  <Project />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.VIRTUAL_MACHINES}
              element={
                <RequireAuth>
                  <VirtualMachines />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.VIRTUAL_MACHINE}
              element={
                <RequireAuth>
                  <VirtualMachine />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.CLUSTERS}
              element={
                <RequireAuth>
                  <Clusters />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.CLUSTER}
              element={
                <RequireAuth>
                  <Cluster />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.NETWORKING}
              element={
                <RequireAuth>
                  <Networking />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.NETWORK}
              element={
                <RequireAuth>
                  <Network />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ROUTER}
              element={
                <RequireAuth>
                  <Router />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.FIREWALL}
              element={
                <RequireAuth>
                  <Firewall />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.FIREWALL_RULE}
              element={
                <RequireAuth>
                  <FirewallRule />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.SECURITY}
              element={
                <RequireAuth>
                  <Security />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.STORAGE}
              element={
                <RequireAuth>
                  <Storage />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.S3_BUCKETS}
              element={
                <RequireAuth>
                  <S3Buckets />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.S3_BUCKET}
              element={
                <RequireAuth>
                  <S3Bucket />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.DATABASES}
              element={
                <RequireAuth>
                  <Databases />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.DATABASE}
              element={
                <RequireAuth>
                  <Database />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.BACKUPS_WORKLOADS}
              element={
                <RequireAuth>
                  <BackupsWorkloads />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.BACKUPS_WORKLOAD}
              element={
                <RequireAuth>
                  <BackupsWorkload />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.BACKUPS_SNAPSHOT}
              element={
                <RequireAuth>
                  <BackupsSnapshot />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.NLP}
              element={
                <RequireAuth>
                  <Nlp />
                </RequireAuth>
              }
            />
            <Route path="*" element={<Navigate to={ROUTES.ROOT} />} />
          </Routes>
        </Container>
      </BrowserRouter>
    ) : (
      <Loader text={"Initializing..."} />
    )
  ) : (
    <Loader text={"Redirecting to login..."} />
  );
};
